.app-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.main-content {
	flex: 1;
	padding-top: 70px; /* Ajustez selon la hauteur de votre navbar */
}

.navbar {
	transition: all 0.3s ease-in-out;
	padding: 15px 0;
}

.navbar.scrolled {
	background-color: #ffffff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	padding: 10px 0;
}

.navbar .brand {
	display: flex;
	align-items: center;
}

.navbar .logo {
	height: 40px;
	margin-right: 10px;
	border-radius: 50%;
}

.navbar .brand-text {
	font-weight: bold;
	font-size: 1.5rem;
	transition: color 0.3s ease-in-out;
}

.navbar:not(.scrolled) .brand-text {
	color: #ffffff;
}

.navbar.scrolled .brand-text {
	color: #343a40;
}

.navbar .nav-link {
	font-size: 1rem;
	margin-left: 15px;
	transition: color 0.3s ease-in-out;
}

.navbar:not(.scrolled) .nav-link {
	color: #ffffff;
}

.navbar.scrolled .nav-link {
	color: #343a40;
}

@media (max-width: 991px) {
	.navbar-collapse {
		background-color: #ffffff;
		padding: 10px;
		border-radius: 5px;
		margin-top: 10px;
	}

	.navbar:not(.scrolled) .navbar-collapse {
		background-color: rgba(52, 58, 64, 0.9);
	}

	.navbar:not(.scrolled) .nav-link {
		color: #ffffff;
	}

	.navbar.scrolled .nav-link {
		color: #343a40;
	}
}