.documentation-navbar {
	transition: all 0.3s ease-in-out;
	padding: 15px 0;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #3855a0;
}

.documentation-navbar.scrolled {
	background-color: #ffffff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	padding: 10px 0;
}

.documentation-navbar .brand {
	display: flex;
	align-items: center;
}

.documentation-navbar .logo {
	height: 40px;
	margin-right: 10px;
	border-radius: 50%;
}

.documentation-navbar .brand-text {
	font-weight: bold;
	font-size: 1.5rem;
	color: #ffffff;
	transition: color 0.1s ease-in-out;
}

.documentation-navbar.scrolled .brand-text {
	color: #3855a0;
}

.documentation-navbar .nav-link {
	font-size: 1rem;
	margin-left: 15px;
	color: #ffffff;
	transition: 0.1s ease-in-out;
}

.documentation-navbar.scrolled .nav-link {
	color: #3855a0;
}

/* Styles pour l'onglet actif */
.documentation-navbar .nav-link.active {
	color: #f78210 !important; /* Orange */
	font-weight: 500;
}

.documentation-navbar.scrolled .nav-link.active {
	color: #f78210 !important; /* Orange, même après le défilement */
	font-weight: 500;
}

/* Effet de survol */
.documentation-navbar .nav-link:hover {
	color: #a45c16;
}

@media (max-width: 991px) {
	.documentation-navbar .navbar-collapse {
		background-color: #3855a0;
		padding: 10px;
		border-radius: 5px;
		margin-top: 10px;
	}

	.documentation-navbar.scrolled .navbar-collapse {
		background-color: #ffffff;
	}

	.documentation-navbar.scrolled .nav-link {
		color: #3855a0;
	}
}

/* Styles pour le menu déroulant */
.custom-dropdown .dropdown-toggle::after {
	display: none;  /* Cache la flèche par défaut */
}

.custom-dropdown .dropdown-menu {
	display: none;
	margin-top: 0;
}

.custom-dropdown:hover .dropdown-menu {
	display: block;
}

.custom-dropdown .dropdown-item {
	color: #3855a0;
	transition: color 0.3s ease-in-out;
}

.custom-dropdown .dropdown-item:hover,
.custom-dropdown .dropdown-item.active {
	background-color: transparent;
	color: #f78210;
}

/* Style pour le titre du dropdown quand un sous-menu est actif */
.custom-dropdown.active .dropdown-toggle {
	color: #f78210 !important;
}

/* Ajustements pour la version scrollée */
.documentation-navbar.scrolled .custom-dropdown .dropdown-menu {
	background-color: #ffffff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.documentation-navbar.scrolled .custom-dropdown .dropdown-item {
	color: #3855a0;
}

.documentation-navbar.scrolled .custom-dropdown .dropdown-item:hover,
.documentation-navbar.scrolled .custom-dropdown .dropdown-item.active {
	color: #f78210;
}