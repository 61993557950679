.home-container {
	max-width: 100%;
	margin: 0 auto;
	padding: 40px 20px;
	/*background-color: #1b1f24;*/
	/*color: #f4f4f4;*/
}

.text-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.welcome-title {
	font-size: 2.5rem;
	font-weight: bold;
	margin-bottom: 20px;
}

.welcome-text {
	font-size: 1.2rem;
	line-height: 1.6;
}

.image-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.home-image {
	max-width: 100%;
	max-height: 300px;
}

.service-row {
	margin-top: 60px;
	background-color: #272b30;
	padding: 40px 20px;
	border-radius: 8px;
}

.service-title {
	font-size: 1.8rem;
	font-weight: bold;
	margin-top: 20px;
	color: #61dafb;
}

.service-text {
	font-size: 1rem;
	line-height: 1.6;
	color: #ddd;
}

/*.info-title {*/
/*	font-size: 1.5rem;*/
/*	font-weight: bold;*/
/*	margin-bottom: 10px;*/
/*	color: #f4f4f4;*/
/*}*/

/*.info-text {*/
/*	font-size: 1rem;*/
/*	color: #bbb;*/
/*	line-height: 1.6;*/
/*}*/
